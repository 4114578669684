import React from "react";
import {
  AboutUserComponent,
  SelectSubjectComponent
} from "tuteria-frontend-components/src/pages/ClientRequestPages";
import groups from "./groups.json";

export class AboutChildPage extends React.Component {
  constructor(props) {
    super(props);
    let {
      request_details = { classes: [{}], online_lessons: "Physical lessons" }
    } = this.props.state;
    if (request_details.classes.length === 0) {
      request_details.classes = [{}];
    }
    this.state = {
      ...request_details
    };
  }
  componentDidMount() {
    this.props.updateState({ currentStep: 1 });
  }
  completeStep = ({ classes, online_lessons }) => {
    this.setState({ classes });
    let {
      updateState,
      navigate,
      state: { request_details }
    } = this.props;
    updateState(
      {
        request_details: { ...request_details, classes, online_lessons }
      },
      true,
      () => {
        navigate("/select-subjects");
      }
    );
  };
  render() {
    const { classes, online_lessons } = this.state;
    return (
      <React.Fragment>
        <AboutUserComponent
          groups={groups}
          classes={classes}
          online_lessons={online_lessons}
          onSubmit={this.completeStep}
        />
      </React.Fragment>
    );
  }
}

export class SelectSubjectPage extends React.Component {
  constructor(props) {
    super(props);
    let {
      request_details = { classes: [], curriculum: [], gender: "" }
    } = this.props.state;
    if ((request_details.classes || []).length === 0) {
      request_details.classes = [];
    }
    this.state = {
      ...request_details
    };
  }
  componentDidMount() {
    let { navigate } = this.props;
    if (this.state.classes.length === 0) {
      navigate("/about-child");
    }
    this.props.updateState({ currentStep: 1 });
  }
  completeStep = ({ classes, curriculum, gender }) => {
    let {
      updateState,
      navigate,
      state: { request_details }
    } = this.props;
    updateState({
      request_details: { ...request_details, classes, curriculum, gender }
    });
    navigate("/location");
  };
  goBack = () => {
    this.props.navigate("/about-child");
  };
  render() {
    return (
      <SelectSubjectComponent
        onSubmit={this.completeStep}
        backAction={this.goBack}
        showBackButton={true}
        data={this.state}
        groups={groups}
        curriculums={["Nigerian", "British", "American", "IPC", "Not sure"]}
      />
    );
  }
}
