import React from "react";
import { LessonScheduleComponent } from "tuteria-frontend-components/src/pages/ClientRequestPages";
import { RequestContext } from "../RequestProvider";
import PriceSummary, { MobilePriceSummary } from "../covid-discount";

export class LessonSchedulePage extends React.Component {
  static contextType = RequestContext;
  constructor(props) {
    super(props);
    let {
      discount_info,
      request_details = {
        days: [],
        hours: "",
        no_of_teachers: 1,
        start_date: null,
        no_of_weeks: null,
        per_hour: 0,
        processing_fee: 3000,
        transport_fare: 0,
        classes: [],
        discount: 0
      },
      hourFactor = []
    } = this.props.state;
    if (discount_info) {
      if (Array.isArray(discount_info)) {
        discount_info = discount_info[0];
      }
      request_details.discount = discount_info.discount;
    }
    this.state = {
      request_details,
      hourFactor,
      discount_info,
      discount: request_details.discount || 0
    };
  }
  hasSlug() {
    let { state } = this.props;
    let result =
      Boolean(state.request_details) && Boolean(state.request_details.slug);
    return result;
  }
  isCompleted() {
    const { state: { status } = {} } = this.props;
    return status === "completed";
  }
  componentDidMount() {
    let {
      match,
      navigate,
      updateState,
      fetchRequestDetailFromSlug
    } = this.props;
    if (!Boolean(match.params.slug) || this.hasSlug()) {
      navigate("/contact-information");
    }
    updateState({ currentStep: 3, allSteps: 5 }, true, () => {
      fetchRequestDetailFromSlug(
        match.params.slug,
        ({ hourFactors, request_dump }) => {
          let { request_details } = this.state;
          let { discount_info } = request_dump;
          if (Array.isArray(discount_info)) {
            discount_info = discount_info[0];
          }
          console.log({ request_dump });
          request_details = {
            ...request_details,
            ...request_dump.request_details,
            discount: discount_info
              ? discount_info.discount
              : request_details.discount || 0
          };
          this.setState({
            request_details,
            hourFactors,
            discount_info,
            discount: request_details.discount
          });
        }
      );
    });
  }
  trackEvent = data => {
    if ((window || {}).dataLayer) {
      let dataLayer = window.dataLayer;
      dataLayer.push({ event: "lesson-amount", budget: data.totalPrice });
    }
  };
  onSubmit = data => {
    const slug = this.props.match.params.slug;

    let saved_data = data;
    if (this.state.discount_info) {
      saved_data.discount_info = this.state.discount_info;
    }
    this.trackEvent(saved_data);
    this.props.saveLessonInfo({ slug, request_details: saved_data }, data =>
      this.props.navigate(`/request-completed/${slug}`)
    );
  };
  goStepBack = () => {
    this.props.navigate(`/contact-information`);
  };
  render() {
    let {
      hourFactor = [{ hour: "1", factor: 50 }, { hour: "1.5", factor: 25 }],
      oneWeekFactor = 1.4
    } =
      this.context.state || {};
    return (
      <React.Fragment>
        <LessonScheduleComponent
          data={this.state.request_details}
          hourFactors={hourFactor}
          oneWeekFactor={oneWeekFactor}
          onSubmit={this.onSubmit}
          checkCoupon={this.props.checkCoupon}
          disabled={this.isCompleted()}
          backAction={this.goStepBack}
          showBackButton
          specialDiscount={
            this.state.discount_info && (
              <PriceSummary discount_info={this.state.discount_info} />
            )
          }
          mobileSummary={this.state.discount_info && <MobilePriceSummary />}
        />
      </React.Fragment>
    );
  }
}
