import React from "react";
import {
  PersonalInfoComponent,
  LocationComponent
} from "tuteria-frontend-components/src/pages/ClientRequestPages";
import jsonData from "tuteria-frontend-components/src/data.json";
import isEmail from "validator/lib/isEmail";
import isMobile from "validator/lib/isMobilePhone";
import includes from "lodash/includes";

const locationValidation = (fields, nested = false) => {
  if (!!fields) {
    let location = nested ? fields : fields.location || {};
    if (location) {
      let stringNotEmpty = ["address", "state", "vicinity", "area"].map(
        Boolean
      );
      return stringNotEmpty.every(Boolean);
    }
    return false;
  }
  return false;
};
const personalInfoValidation = (fields = {}) => {
  if (!!fields) {
    let stringNotEmpty = [
      "first_name",
      "last_name",
      "email",
      "gender",
      "phone_number"
    ]
      .map(x => Boolean)
      .every(Boolean);
    let validPhoneNumber = isMobile(fields.phone_number || "", "en-NG");
    let validEmail = isEmail(fields.email || "");
    return stringNotEmpty && validPhoneNumber && validEmail;
  }
  return false;
};
function profileData(props) {
  return {
    //loading: props.loading,
    defaultValidation: false,
    errors: {
      first_name: ["This field is required"],
      last_name: ["Your last name is required"],
      email: ["A valid email is required"],
      gender: ["You forgot to place a gender"],
      country: ["Please this field is required"],
      phone_number: ["Please insert a valid phone number"],
      phone_combined: ["Please insert a valid phone number"],
      combined: "Your first name and last name is invalid"
    },
    customValidation: personalInfoValidation,
    whereYouHeardOptions: props.state.marketing_channels,
    validateField: (state, field, err) => {
      if (field === "combined") {
        return !!state.first_name && !!state.last_name;
      }
      if (field === "phone_number") {
        return (
          Boolean(state.phone_number) && isMobile(state.phone_number, "en-NG")
        );
      }
      if (field === "email") {
        return Boolean(state.email) && isEmail(state.email);
      }
      if (field === "phone_combined") {
        return (
          Boolean(state.phone_number) && isMobile(state.phone_number, "en-NG")
        );
      }
      if (includes(["first_name", "last_name", "email"], field)) {
        return Boolean(state[field]);
      }
      return true;
    }
  };
}

function locationData(props) {
  return {
    loading: props.loading,
    defaultValidation: false,
    updateState: props.updateState,
    validateField: (state, field, err) => {
      if (includes(["combined_state"], field)) {
        return !!state.state && !!state.vicinity && !!state.area;
      }
      if (includes(["address", "state", "vicinity", "area"], field)) {
        return !!state[field];
      }
    },
    customValidation: field => locationValidation(field, true),
    defaultCoordinate: { lat: 6.5403929, lng: 3.3741649 },
    states: jsonData.states.map(x => x.name),
    getVicinities: props.getVicinities,
    getAreas: vicinity => val => {
      return new Promise((resolve, reject) =>
        resolve(["vicinity1", "vicinity2"])
      );
    },
    errors: {
      address: ["Your address is required"],
      state: ["Please select a state"],
      vicinity: ["You forgot to input your vicinity"],
      area: ["One more to go, we need a popular location in your vicinity"],
      combined_state: ["Please ensure your vicinity is inputed"]
    }
  };
}

export class LocationPage extends React.Component {
  constructor(props) {
    super(props);
    let { location = {} } = this.props.state;
    this.state = {
      location
    };
  }
  componentDidMount() {
    this.props.updateState({ currentStep: 2 });
    let {
      state: { request_details },
      navigate
    } = this.props;
    if (request_details.classes.length === 0) {
      navigate("/about-child");
    }
  }
  trackEvent = data => {
    if ((window || {}).dataLayer) {
      let dataLayer = window.dataLayer;
      dataLayer.push({
        event: "narrowed-location",
        state: data.state,
        vicinity: data.vicinity
      });
    }
  };
  nextStep = (location, errorCallback) => {
    this.setState({ location });
    this.trackEvent(location);
    this.props.updateState({ location }, true, () => {
      this.props.navigate("/contact-information");
    });
    return new Promise((resolve, reject) => {
      resolve({ success: {}, error: null });
    });
  };

  goBack = () => {
    this.props.navigate("/select-subjects");
  };

  render() {
    return (
      <LocationComponent
        hasGeocode={false}
        onSubmit={this.nextStep}
        {...locationData(this.props)}
        customValidation={this.localValidation}
        data={this.state.location}
        showBackButton={true}
        backAction={this.goBack}
      />
    );
  }
}

export class ContactInfoPage extends React.Component {
  constructor(props) {
    super(props);
    let { location = {}, personal_info = {} } = this.props.state;
    this.state = {
      location,
      personal_info,
      loading: false
    };
  }
  componentDidMount() {
    this.props.updateState({ currentStep: 2 });
    let { navigate } = this.props;
    if (Object.keys(this.state.location).length === 0) {
      navigate("/location");
    }
  }
  formatPhoneNumber = val => {
    //the problem with this implementation is that we have used this
    // before in the tutor application flow and it would have been just
    //easier to go down there and take it.

    if (val[0] === "0") {
      return "234".concat(val.substring(1));
    }
    return "234".concat(val);
  };
  transformNumber(number, code = "234") {
    if (number.startsWith("0")) {
      return number.replace("0", code);
    }
    return number;
  }
  onSubmit = personal_info => {
    let { location } = this.state;
    let {
      navigate,
      saveInitialData,
      state: { allSteps }
    } = this.props;
    this.setState({
      loading: true
    });
    saveInitialData(
      {
        ...personal_info,
        phone_number: this.transformNumber(personal_info.phone_number)
      },
      location,
      allSteps + 1,
      slug => {
        navigate(`/lesson-schedule/${slug}`);
      },
      () =>
        this.setState({
          loading: false
        })
    );
  };

  goBack = () => {
    this.props.navigate("/location");
  };

  render() {
    return (
      <PersonalInfoComponent
        onSubmit={this.onSubmit}
        loading={this.state.loading}
        {...profileData(this.props)}
        data={this.state.personal_info}
        showBackButton={true}
        backAction={this.goBack}
      />
    );
  }
}
