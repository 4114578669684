import React from "react";
import { PageContainer } from "tuteria-frontend-components/src/pages/ClientRequestPages/ClientPageWrapper";
import { Switch, Redirect } from "react-router-dom";
import { injectGlobal } from "styled-components";
import { AboutChildPage, SelectSubjectPage } from "./AboutChildPage";
import { ContactInfoPage, LocationPage } from "./ContactInfoPage";
import { LessonSchedulePage } from "./LessonSchedulePage";
import ClientThankYouPage from "./ClientThankYouPage";
import { color } from "tuteria-frontend-components/src/siteStyle";
import { AnalyticsRoute } from "../utils";
let tabs = [
  "About Child",
  "Contact Information",
  "Lesson Schedule",
  "Complete Request"
];

injectGlobal`
body {
  background: ${color.white}!important;
}
#root {
  overflow-x: hidden;
}
`;
export class ParentFlow extends React.Component {
  navigate = path => {
    let { basePath = "" } = this.props;
    this.props.history.push(`${basePath}${path}`);
  };
  render() {
    let { currentStep } = this.props.state.state;
    let { basePath = "" } = this.props;
    return (
      <PageContainer steps={tabs} current={currentStep}>
        <Switch>
          <AnalyticsRoute
            path={`${basePath}/about-child`}
            render={props => (
              <AboutChildPage
                {...props}
                {...this.props.state}
                navigate={this.navigate}
              />
            )}
          />
          <AnalyticsRoute
            path={`${basePath}/select-subjects`}
            render={props => (
              <SelectSubjectPage
                {...props}
                {...this.props.state}
                navigate={this.navigate}
              />
            )}
          />
          <AnalyticsRoute
            path={`${basePath}/location`}
            render={props => (
              <LocationPage
                {...props}
                {...this.props.state}
                navigate={this.navigate}
              />
            )}
          />
          <AnalyticsRoute
            path={`${basePath}/contact-information`}
            render={props => (
              <ContactInfoPage
                {...props}
                {...this.props.state}
                navigate={this.navigate}
              />
            )}
          />
          <AnalyticsRoute
            path={`${basePath}/lesson-schedule/:slug`}
            render={props => {
              return (
                <LessonSchedulePage
                  {...props}
                  {...this.props.state}
                  navigate={this.navigate}
                />
              );
            }}
          />

          <AnalyticsRoute
            path={`${basePath}/request-completed/:slug`}
            render={props => (
              <ClientThankYouPage
                {...props}
                {...this.props.state}
                navigate={this.navigate}
              />
            )}
          />
          <Redirect to={`${basePath}/about-child`} />
        </Switch>
      </PageContainer>
    );
  }
}

export default ParentFlow;
