import React from "react";
import { Box, Stack, Text, Flex, Badge } from "@chakra-ui/core";
import Pluralize from "pluralize";

export default function PriceSummary({
  hourlyRate,
  number_of_hours,
  number_of_lessons,
  lesson_fee,
  processing_fee,
  total_fee,
  discount,
  discount_info = {
    discount: 15,
    duration: 2,
    deadline: "June 13th",
    name: "COVID-19 Relief"
  }
}) {
  return (
    <Box>
      <Stack spacing={5} borderTopWidth="2xs" borderBottomWidth="2xs">
        <Flex justify="space-between">
          <Text>
            ₦{hourlyRate.toLocaleString()} &times;{" "}
            {Pluralize("hr", number_of_hours, true)} &times;{" "}
            {Pluralize("lesson", number_of_lessons, true)}
          </Text>
          <Text ml={6}>₦{lesson_fee.toLocaleString()}</Text>
        </Flex>
        <Flex justify="space-between">
          <Box>
            <Text>Service fee</Text>
            <Text fontSize="sm" color="gray.500">
              One-time payment
            </Text>
          </Box>
          <Text ml={6}>₦{processing_fee.toLocaleString()}</Text>
        </Flex>{" "}
        {discount > 0 &&
          discount_info && (
            <React.Fragment>
              <Flex justify="space-between" alignItems="center">
                <Box lineHeight="short">
                  <Badge variantColor="green" px={1} fontSize={8}>
                    Automatically applied
                  </Badge>
                  <Text>
                    {discount_info.discount}% {discount_info.name}
                  </Text>
                </Box>
                <Text color="green.500" ml={6}>
                  &minus; ₦{discount.toLocaleString()}
                </Text>
              </Flex>
              <Box mt={6} rounded="lg" bg="gray.100" p={6}>
                Discount runs until {discount_info.deadline} and only applies to
                your first {discount_info.duration}{" "}
                {Pluralize("booking", discount_info.duration)}.
              </Box>
            </React.Fragment>
          )}
      </Stack>
      <Flex pt={6} justify="space-between" fontWeight="bold">
        <Text>Payment</Text>
        <Text>₦{total_fee.toLocaleString()}</Text>
      </Flex>
    </Box>
  );
}

export function MobilePriceSummary() {
  return (
    <Box
      mr={6}
      lineHeight="shorter"
      visibility={["visible", "visible", "hidden"]}
    >
      <Badge variantColor="green" px={1} fontSize={8}>
        Discounted
      </Badge>
    </Box>
  );
}
