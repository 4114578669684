import React from "react";
import styled from "styled-components";
import { InputComponent } from "../../../form";
import { spacing, Icon, Div } from "../../components";
import { color } from "../../../siteStyle";
import DayPickerInput from "react-day-picker/DayPickerInput";

export const datePickerStyles = `
/* DayPicker styles */

.DayPicker {
  display: inline-block;
}

.DayPicker-wrapper {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-bottom: 1rem;
  flex-direction: row;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media(max-width: 768px) {
    display: table-row;
  }
}

.DayPicker-Month {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 0 1rem;
  margin-top: 1rem;
}
.DayPicker-Day--today {
    color: ${color.blue.primary}!important;
    font-weight: 700;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
  position: absolute;
  cursor: pointer;
  top: 1rem;
  right: 1.5rem;
  margin-top: 2px;
  color: #8b9898;
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5rem;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  padding: 0 0.5rem;
  display: table-caption;
  text-align: left;
  margin-bottom: 0.5rem;
}

.DayPicker-Caption > div {
  font-size: 1.15rem;
  font-weight: 500;
}

.DayPicker-Weekdays {
  margin-top: 1rem;
  display: table-header-group;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5rem;
  font-size: 0.875em;
  text-align: center;
  color: #8b9898;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5rem;
  text-align: right;
  vertical-align: middle;
  min-width: 1rem;
  font-size: 0.75em;
  cursor: pointer;
  color: #8b9898;
  border-right: 1px solid #eaecec;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5rem;
}

.DayPicker-TodayButton {
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  color: #4a90e2;
  font-size: 0.875em;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #d0021b;
  font-weight: 700;
}

.DayPicker-Day--outside {
  cursor: default;
  color: #8b9898;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  color: #f0f8ff;
  background-color: #4a90e2;
  border-radius: 100%;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51a0fa;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f0f8ff;
  border-radius: 50%;
}

/* DayPickerInput */

.DayPickerInput {
  display: block;
  line-height: 1;
}

.DayPickerInput input {
    color: #47525D;
    line-height:18px;
    outline: none;
    box-shadow: none;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  left: 0;
  z-index: 1;
  position: absolute;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.DayPicker-Day--disabled {
  color: rgb(191, 192, 194);
}
`;
export class DatePickerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.input = null;
    this.state = {
      selectedDay: undefined
    };
    this.setDateInputRef = element => {
      this.input = element;
    };
    this.focusDateInput = () => {
      if (this.input) {
        this.input.focus();
      }
    };
    this.handleDayChange = selectedDay => {
      this.setState({ selectedDay });
      this.props.onChange(selectedDay);
    };
  }
  formater = date => {
    let months = [
      "January",
      "Febuary",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
  };
  render() {
    const {
      label,
      placeholder,
      onChange,
      value,
      field_name,
      css = ""
    } = this.props;

    return (
      <Div
        css={`
          position: relative;
          svg {
            position: absolute;
            right: 16px;
            top: 42px;
            z-index: 0;
            fill: ${color.green.primary};
            &:after {
              content: "";
              display: block;
            }
          }
          ${datePickerStyles};
          ${css};
        `}
      >
        <InputComponent
          innerRef={this.setDateInputRef}
          onClick={this.focusDateInput}
          type="date"
          label={label}
          value={value}
          field_name={field_name}
          placeholder={placeholder}
        >
          <DayPickerInput
            value={Boolean(value) ? new Date(value).toLocaleDateString() : ""}
            formatDate={this.formater}
            inputProps={{
              onFocus: this.props.onFocus,
              onBlur: this.props.onBlur
            }}
            onDayChange={
              this.handleDayChange // parseDate={this.formater}
            }
            className="date-picker"
            dayPickerProps={{ disabledDays: [{ before: new Date() }] }}
          />
        </InputComponent>
        <Icon name="calender" width={18} height={20} />
      </Div>
    );
  }
}

export default DatePickerComponent;
